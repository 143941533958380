import { GlobalContext, GlobalSetterContext, useGlobal } from '../context/GlobalContext';
import React, { act, useContext } from 'react'
import { Link } from 'react-router-dom';
import { BsFacebook, BsInstagram, BsPinterest, BsTwitter, BsTwitterX } from 'react-icons/bs'
import { ArrowRightIcon } from '@heroicons/react/outline';

import Logo from './Logo';
import { Routes, Route } from "react-router-dom";
import { BiLogoFacebook, BiLogoInstagram, BiLogoInstagramAlt, BiLogoPinterest, BiLogoPinterestAlt, BiLogoTwitter } from 'react-icons/bi';
import { useLocation } from 'react-router-dom';
import { RxPlay } from 'react-icons/rx';

function MobileMenu() {

    let [horizontalNav, toggler, mobileMenu] = useContext(GlobalContext)
    let [setHorizontalNav, setToggler, setMobileMenu] = useContext(GlobalSetterContext)

    const location = useLocation();
    const { hash, pathname, search } = location

    console.log(mobileMenu)


    return (
        <div>
            <div className={` mt-[0px] md:mt-[0px] overflow-y-auto  duration-300 
            flex flex-col w-[300px] sm:w-[390px] h-screen fixed text-white top-[0px]
            ${toggler ? `left-[-2px]` : `left-[-400px]`} blur:hidden border-t-[15px] border-b-[15px] border-red-500 shadow-xl`}>

                <div className=' bg-white h-screen pt-[0px]'>
                    <div className='pl-[50px]  pb-3 pt-1 border-b-[1px] mb-10'>
                        <Logo colorInfo={"gray"} />
                    </div>
                    <div className=' '>
                        {
                            horizontalNav.map((item) => {

                                let activatedLink = false;

                                if (pathname === item.link) {
                                    activatedLink = true;
                                    console.log("<<<<<<<===========")
                                } else {
                                    activatedLink = false;
                                    console.log("===========>>>>>>")
                                }

                                return (
                                    <div key={item.id}
                                        className={`${activatedLink ? ' border-b-[1px] border-b-white/60 font-bold' : ''} text-black pl-[50px] pr-5 hover:text-gray-400 py-[10px] text-[17px] md:text-[15px]`}
                                        onClick={() => {
                                            window.location.href = item.link
                                        }}
                                    >
                                        <div className=' flex place-content-between '>
                                            {item.title}
                                            {
                                                activatedLink ? <ArrowRightIcon className=' relative top-[2px] size-[20px] font-normal' /> : ''
                                            }

                                        </div>

                                    </div>
                                )

                                {/* <span key={item.link}
                                    to={"#"}
                                    className={`${activatedLink ? 'border-b-[1px] border-b-white/60 font-bold' : ''} hover:text-gray-400 pl-[50px] pr-5
                                py-[10px] flex  text-black `}
                                    onClick={() => {
                                        window.location.href = item.link
                                    }}
                                >

                                    <span className='font-normal text-[17px] md:text-[15px]'>{item.title} </span>
                                </span> */}
                            })
                        }
                    </div>


                    <div className='relative flex gap-5 items-center mt-28'>
                        <div className='bg-cyan-600 w-[5px] h-[1.5px] '></div>
                        <Link href="https://www.facebook.com/comvarse" target='_blank'><span><BiLogoFacebook size={27} className=' text-black hover:-translate-y-1.5 duration-300 hover:text-gray-500' /></span></Link>
                        <Link href="https://www.twitter.com/comvarse" target='_blank'><span><BiLogoTwitter size={28} className=' text-black hover:-translate-y-1.5 duration-300 hover:text-gray-500' /></span></Link>
                        <Link href="https://www.instagram.com/comvarse" target='_blank'><span><BiLogoInstagram size={28} className=' text-black hover:-translate-y-1.5 duration-300 hover:text-gray-500' /></span></Link>
                        <Link href="https://www.pinterest.com/comvarse" target='_blank'><span><BiLogoPinterestAlt size={28} className=' text-black hover:-translate-y-1.5 duration-300 hover:text-gray-500' /></span></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileMenu
