import React from 'react'
import Header from '../../components/Header'
import HeroAnimated from '../../components/HeroAnimated'
import Hero from '../../components/Hero'


import SliderBox from '../../components/Slider'
import SlideCarousel3 from '../../components/SlideCarousel3'
import Horizontal from '../../components/Horizontal'
import Subtitle from '../../components/Subtitle'
import Footer from '../../components/Footer'
import HorizontalSmall from '../../components/HorizontalSmall'
import Prefooter from '../../components/Prefooter'
import InsightsBox from '../../components/InsightsBox'
import CompOne from '../../components/CompOne'
import H16 from '../../components/H16'
import H32 from '../../components/H32'
import H64 from '../../components/H64'


function Home() {

    const images = [
        'https://via.placeholder.com/800x400/ff5733/fff',
        'https://via.placeholder.com/800x400/33ff57/fff',
        'https://via.placeholder.com/800x400/5733ff/fff',
    ];

    const insights = [
        {
            id: 1,
            category: "Blockchain",
            title: "DBS – Blockchain: Bringing Intelligence into Payments",
            description: `DBS - Digital Business Leaders Series 2021, themed “Back to the Future”, featured the most influential technology developments.`,
            ftrcategory: "Payments",
            img: "img/globaldbs.webp",
            link: "https://www.youtube.com/watch?v=1qXs_A0rPrE"
        },
        {
            id: 2,
            category: "Software Development",
            title: "The Next Decade of Software Development - Richard Campbell",
            description: "How will software development evolve in the 2020s? Join Richard Campbell as he explores the landscape of technology.",
            ftrcategory: "Youtube",
            img: "img/sftw.jpg",
            link: "https://www.youtube.com/watch?v=ND_AjF_KTD8"
        },
        {
            id: 3,
            category: "Telecommunications",
            title: "Introduction to CAMARA, the Global Telco API Alliance",
            description: "Mahesh Chapalamadugu, Verizon presents the Global Telco API Alliance",
            ftrcategory: "Youtube",
            img: "img/camara.webp",
            link: "https://www.youtube.com/watch?v=uhto19UIS7A"
        },
        {
            id: 4,
            category: "Software Development",
            title: "The Most Powerful Software Development Process Is The Easiest • Dave Farley",
            description: "What would an ideal software development process look like?",
            ftrcategory: "Youtube",
            img: "img/scrt.jpg",
            link: "https://www.youtube.com/watch?v=HDyeiZimaOU"
        },
    ]


    return (
        <div>
            <Header bg={"light"} />

            {/* <SliderBox /> */}
            <SlideCarousel3 />

            <div>
                <Subtitle title={"Outsourcing"} />
                <CompOne />
                <H64 />
            </div>

            <div className=' '>
                <Subtitle title={"Business Insights"} />
                <InsightsBox insights={insights} />
                {/* <Horizontal /> */}

                {/* <Subtitle title={"Industry Practice"} />
                <HorizontalSmall /> */}

            </div>

            <div className=' h-20'></div>
            <div>
                <Prefooter />
            </div>

            <Footer />
        </div>
    )
}

export default Home
