import React from 'react'
import Header from '../../components/Header'
import HeroAnimated from '../../components/HeroAnimated'
import SliderBox from '../../components/Slider'
import Hero from '../../components/Hero'
import Prefooter from '../../components/Prefooter'
import Footer from '../../components/Footer'
import Datatable from '../../components/Datatable'
import H64 from '../../components/H64'

const Contact = () => {
    return (
        <div>

            <Header bg={"light"} />
            <HeroAnimated
                imgLink={"https://unbound.radiantthemes.com/wp-content/uploads/2018/06/business-agency-3-banner.jpg"}
                title={"Stay ahead in business? Work with us."}
            />



            <div>
                <Prefooter />
            </div>

            <Footer />
        </div>
    )
}

export default Contact
