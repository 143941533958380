import React, { createContext, useContext, useState } from 'react'
import Home from '../pages/home/Home';
import Contact from '../pages/contact/Contact';
import About from '../pages/about/About';
import OurWork from '../pages/our-work/OurWork';
import CBDCSmartContracts from '../pages/cbdc-smartcontracts/CBDCSmartContracts';
import APIGateway from '../pages/api-gateway/APIGateway';
import Outsourcing from '../pages/outsourcing/Outsourcing';

const navjson = [
    {
        id: 1,
        title: "Home",
        img: "/img/english.jpg",
        link: "/",
        page: <Home />
    },
    {
        id: 2,
        title: "CBDC Contracts",
        link: "/cbdc-smartcontracts",
        page: <CBDCSmartContracts />
    },
    {
        id: 3,
        title: "API Gateway",
        link: "/api-gateway",
        page: <APIGateway />
    },
    {
        id: 4,
        title: "Outsourcing",
        link: "/outsourcing",
        page: <Outsourcing />
    },
    {
        id: 5,
        title: "Contact us",
        link: "/contact",
        page: <Contact />
    },

]

const mobileMenuJson = [
    {
        title: "Home",
        img: "/img/english.jpg",
        link: "/"
    },
    {
        title: "Our Work",
        link: "/our-work"
    },
    {
        title: "Contact Us",
        link: "/contact"
    },



]

const togglerState = false;

const GlobalContext = createContext();
const GlobalSetterContext = createContext();

export function useGlobal() {
    return useContext(GlobalContext);
}

function GlobalProvider({ children }) {
    let [horizontalNav, setHorizontalNav] = useState(navjson)
    let [togger, setToggler] = useState(togglerState)
    let [mobileMenu, setMobileMenu] = useState(mobileMenuJson)

    let value = [
        horizontalNav,
        togger,
        mobileMenu
    ]

    let valueSetters = [
        setHorizontalNav,
        setToggler,
        setMobileMenu
    ]



    return (
        <GlobalContext.Provider value={value}>
            <GlobalSetterContext.Provider value={valueSetters}>
                {children}
            </GlobalSetterContext.Provider>
        </GlobalContext.Provider >
    )
}

export { GlobalProvider, GlobalContext, GlobalSetterContext }