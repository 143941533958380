import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

function Logo({ colorInfo }) {
    const [color, setColor] = useState("white")

    useEffect(() => {
        if (colorInfo) {
            if (colorInfo === "white") {
                setColor("text-white");
            } else {
                setColor(`text-` + colorInfo + `-700`)
            }

        }
    }, [colorInfo])
    return (
        <div>
            <div className='flex'>
                <NavLink onClick={() => {
                    window.location.href = "/"
                }}>
                    <span className={` text-[32px]  font-sans font-black ${color} tracking-normal `}>
                        <span className={"   text-[30px] mr-[-1px] relative top-[0px]"}>
                            <span className=' text-[38px] italic '>veu.</span>
                        </span>
                    </span>
                </NavLink>
            </div>
        </div >
    )
}

export default Logo
