import React, { useContext, useEffect, useRef } from 'react'
import { MenuIcon } from '@heroicons/react/outline'
import { GlobalContext, GlobalProvider, GlobalSetterContext, useGlobal } from '../context/GlobalContext';
import MobileMenu from './MobileMenu';


const Hamburger = () => {

    let [horizontalNav, toggler, mobileMenu] = useContext(GlobalContext)
    let [setHorizontalNav, setToggler, setMobileMenu] = useContext(GlobalSetterContext)

    //setHorizontalNav(horizontalNav)

    //console.log(horizontalNav)

    const toggleMenu = (e) => {
        e.preventDefault()
        setToggler(!toggler)
    }

    useRef(() => {

    })

    return (
        <div>
            <MenuIcon className='h-6 w-7' onClick={(e) => toggleMenu(e)} />
            <MobileMenu />
        </div>
    )
}

export default Hamburger
