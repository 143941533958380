import React from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import { Routes, Route } from "react-router-dom";
import { useGlobal } from "./context/GlobalContext";

function App({ Component, pageProps }) {
  const [horizontalNav, setHorizontalNav] = useGlobal();
  return (

    <div>
      <Routes>
        {
          horizontalNav.map((item) => {
            return <Route key={item.link} path={item.link + "/*"} element={item.page} />
          })
        }

      </Routes>
    </div>
  );
}

export default App;
