import React, { useEffect, useState } from 'react'
import { useGlobal } from '../context/GlobalContext'
import { Link, useRoutes } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const activeTab = (history, path) => {
    if (history.location.pathname === path) {
        return { color: "red" };
    }
};




function MiddleMenu({ router }) {
    const [horizontalNav] = useGlobal()
    const [activeLink, setActive] = useState(false)

    const location = useLocation();
    const { hash, pathname, search } = location




    return (
        <div>
            <div className='flex space-x-10'>

                {
                    horizontalNav.map(function (item) {
                        let activatedLink = false;

                        if (pathname === item.link) {
                            activatedLink = true;

                        } else {
                            activatedLink = false;
                        }
                        return (
                            <span
                                key={item?.id}

                                exact="true"
                                className={`${activatedLink ? 'border-b-[1px] border-b-white/60 font-bold' : ''} text-white relative rounded-none hover:text-white/50 hover:border-b-white/50 hover:text-white  pt-[2px] pb-[0px]  cursor-pointer text-[14px]`}
                                /*  className={({ isActive, isPending, isTransitioning }) =>
                                     [
                                         isPending ? "pending" : "",
                                         isActive ? " border-b-[1px] border-b-white/60" : "",
                                         isTransitioning ? "transitioning" : "",
                                     ].join(" text-white relative rounded-none hover:text-white/50 hover:border-b-white/50 hover:text-white  pt-[2px] pb-[0px]  cursor-pointer text-[14px]")
                
                                 } */
                                onClick={() => {
                                    window.location.href = item.link
                                }}

                            >
                                {item?.title}
                            </span>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default MiddleMenu
