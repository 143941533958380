import { React, useState, useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import MiddleMenu from './MiddleMenu'
import Logo from './Logo'
import Hamburger from './Hamburger'

const routeConfig = [

]

function Header(props) {
    const router = useRoutes(routeConfig)

    const [navbg, setNavbg] = useState(false);
    const [defaultBg, setDefaultBg] = useState("light")

    const TOP_OFFSET = 50;
    useEffect(() => {
        const handleScroll = () => {

            if (window.scrollY >= TOP_OFFSET) {
                setNavbg(true)
            } else {
                setNavbg(false)
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    useEffect(() => {
        if (props.bg == "dark") {
            setDefaultBg("bg-gray-900")
        } else {
            setDefaultBg("bg-transparent")
        }

    }, [props])


    return (
        <div>
            <div className={`fixed
            w-full
            top-0 z-10 transition duration-1000
            ${navbg ? "bg-red-800 border-b-white/0" : defaultBg} 
            border-b-[1px] border-white/20
             pt-3 pb-1 z-[11]
            `}>


                <div className='mx-5 '>
                    <div className=' max-w-[1200px] mx-auto w-full'>
                        <header className=' relative flex items-center justify-between gap-10'>
                            <div className='relative top-[-3px]'>
                                <Logo colorInfo={"white"} />
                            </div>
                            <div className='hidden lg:block grow relative'>
                                <MiddleMenu router={router} />
                            </div>
                            <div className='hover:text-white/60 hover:cursor-pointer flex items-center text-white space-x-2 border-1 border-gray-300 rounded-full py-2'>
                                <Hamburger />
                            </div>
                        </header>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
